import { Injectable } from '@angular/core';
import * as DaVinciApi from '@amc-technology/davinci-api';
import { ConnectService } from './connect.service';
import { LoggerService } from './logger.service';
import { IInteraction, IAppConfiguration, setSupportedChannels, CHANNEL_TYPES, CONTEXTUAL_OPERATION_TYPE, LOG_LEVEL } from '@amc-technology/davinci-api';
import { getContactId } from './util/getContactId';

declare let connect: any; // Object to access Amazon Connect API

@Injectable({
  providedIn: 'root'
})
export class ChannelApiService {
  private log: (logLevel: LOG_LEVEL, fName: string, message: string, object?: any) => void = () => {};
  public channelToDaVinci = {};
  public DaVinciToChannel = {};
  public connectCcpUrl;
  public awsRegion: string;
  private lastConnectPresence = '';
  private lastDaVinciPresence = '';
  private appName = '';
  private _config: IAppConfiguration;

  /* Providing app config to other components
     and services through a getter. This is to discourage
     writing to the config field, as writing to the config object
     will take effect anywhere this object is referenced. (Even if
     the other components or services use this getter only once)
  */
  get config() {
    return this._config;
  }

  constructor(private _connectService: ConnectService, private loggerService: LoggerService) {
    this.log = this.loggerService.log;
  }

  async initialize() {
    await DaVinciApi.registerContextualControls(this.ContextualControlCallback.bind(this));
    await DaVinciApi.registerOnPresenceChanged(this.onDaVinciPresenceChanged);
    await DaVinciApi.registerClickToDial(this.onClickToToDial);
    await DaVinciApi.registerOnLogout(this.logout);
    this._config = await DaVinciApi.initializeComplete(this.loggerService.logger);
    this.appName = <string>(<unknown>this.config.name);
    this.channelToDaVinci = this.config.ChannelToDaVinci.variables; // Maps Channel Presence to DaVinci presence
    this.DaVinciToChannel = this.config.DaVinciToChannel.variables; // Maps DaVinci Presence to Channel presence
    this.connectCcpUrl = this.config.variables.ConnectCcpUrl;
    this.awsRegion = this.config?.variables?.['AWS Region']?.toString();

    if (!this.awsRegion) {
      this.loggerService.logger.logCritical(`ChannelApiService.initialize(): AWS Region was not found! Defaulting to ${ConnectService.DEFAULT_AWS_REGION}`);
      this.awsRegion = ConnectService.DEFAULT_AWS_REGION;
    }

    this._connectService.presence.subscribe(this.onConnectPresenceChanged);
    this._connectService.setSupportedChannels$.subscribe((connectData) => {
      this.log(LOG_LEVEL.Debug, 'ChannelApiService.setSupportedChannels$', 'connectData: ', connectData);
      const username = connectData.username;
      const availableChannels = connectData.channels;
      const channels = [];

      if (availableChannels) {
        if ('VOICE' in availableChannels) {
          channels.push({
            channelType: CHANNEL_TYPES.Telephony,
            idName: 'Agent',
            id: username,
            validOperations: [],
            validPresences: []
          });
        }

        if ('CHAT' in availableChannels) {
          channels.push({
            channelType: CHANNEL_TYPES.Chat,
            idName: 'chat',
            id: '1'
          });
        }

        if ('TASK' in availableChannels) {
          channels.push({
            channelType: CHANNEL_TYPES.Email,
            idName: 'email',
            id: username
          });
        }
      }

      setSupportedChannels(channels);
    });
  }

  getLastConnectPresence() {
    return this.lastConnectPresence;
  }

  onClickToToDial = async (number: string) => {
    try {
      this.loggerService.logger.logDebug(
        `Channel API onClickToToDial:
        number=${number}`
      );
      this._connectService.MakeOutboundCall(number);
    } catch (e) {
      this.loggerService.logger.logError(
        `Channel API onClickToToDial:
        number=${number}
        error=${JSON.stringify(e)}`
      );
    }
  };

  logout = async (reason?: string): Promise<void> => {
    const fName = 'logout';
    try {
      const logoutLink: any = this._config.LogoutLink;
      this.log(LOG_LEVEL.Information, `ChannelApiService ${fName}`, 'Received logout event from DaVinci, flushing logs and attempting to log out of AmazonConnect. Reason: ', reason);
      fetch(logoutLink, { credentials: 'include', mode: 'no-cors' }).then(() => {
        const eventBus = connect.core.getEventBus();
        eventBus.trigger(connect.EventType.TERMINATE);
      });
      await this.loggerService.logger.pushLogsAsync();

      if (this._connectService.getPopup()) {
        (await this._connectService.initLogoutFromConnect()).self.close();
      }
      return Promise.resolve();
    } catch (e) {
      this.log(LOG_LEVEL.Error, `ChannelApiService ${fName}`, 'Failed to logout. Error: ', e);
    }
  };

  onConnectPresenceChanged = (connectPresence: string) => {
    const fName = 'onConnectPresenceChanged';
    try {
      this.log(LOG_LEVEL.Debug, `ChannelApiService ${fName}`, `connectPresence=${connectPresence}`);
      if (connectPresence !== this.lastConnectPresence) {
        this.loggerService.logger.logDebug(
          `Channel API onConnectPresenceChanged:
          connectPresence=${connectPresence}`
        );
        this.lastConnectPresence = connectPresence;

        const daVinciPresence = this.channelToDaVinci[connectPresence];

        if (daVinciPresence === undefined) {
          this.log(LOG_LEVEL.Debug, `ChannelApiService ${fName}`, `Unknown Presence=${connectPresence}`);
        } else {
          if (daVinciPresence !== this.lastDaVinciPresence) {
            const presenceArray: string[] = daVinciPresence.split('|');
            const newPresence: string = presenceArray[0];

            this.lastDaVinciPresence = daVinciPresence;

            if (presenceArray.length > 1) {
              const newReason = presenceArray[1];
              DaVinciApi.setPresence(newPresence, newReason);
            } else {
              DaVinciApi.setPresence(newPresence);
            }
          }
        }
      }
    } catch (e) {
      this.loggerService.logger.logError(
        `Channel API onConnectPresenceChanged:
        connectPresence=${connectPresence}
        error=${JSON.stringify(e)}`
      );
    }
  };

  onDaVinciPresenceChanged = async (daVinciPresence: string, reason?: string, initiatingApp?: string) => {
    if (initiatingApp !== this.appName) {
      const fName = 'onDaVinciPresenceChanged';
      try {
        this.log(LOG_LEVEL.Debug, fName, `ChannelApiService ${fName} : daVinciPresence=${daVinciPresence} : reason=${reason} : initiatingApp=${initiatingApp}`);
        if (daVinciPresence !== this.lastDaVinciPresence) {
          this.loggerService.logger.logDebug(
            `Channel API onDaVinciPresenceChanged:
            daVinciPresence=${daVinciPresence}`
          );

          if (daVinciPresence === null || daVinciPresence === '') {
            this.loggerService.logger.logError('Amazon Connect CTI - Channel API Service : onDaVinciPresenceChanged : presence is null or empty');
            return;
          }
          let newDaVinciPresence = daVinciPresence;
          if (reason !== null) {
            if (reason === '') {
              this.loggerService.logger.logError('Amazon Connect CTI - Channel API Service : onDaVinciPresenceChanged : reason is not null and empty');
              return;
            }
            newDaVinciPresence += '|' + reason;
          }

          const connectPresence = this.DaVinciToChannel[newDaVinciPresence];

          if (!connectPresence) {
            this.loggerService.logger.logDebug(
              `Channel API onDaVinciPresenceChanged:
              Unknown connectPresence. DaVinci Presence=${daVinciPresence}`
            );
          } else if (connectPresence !== this.lastConnectPresence) {
            await this._connectService.setPresence(connectPresence);
            DaVinciApi.setPresence(daVinciPresence, reason);
            this.lastDaVinciPresence = newDaVinciPresence;
            this.lastConnectPresence = connectPresence;
          }
        }
      } catch (e) {
        this.loggerService.logger.logError(
          `Channel API onDaVinciPresenceChanged:
          daVinciPresence=${daVinciPresence}
          error=${JSON.stringify(e)}`
        );
      }
    }
  };

  ContextualControlCallback(contact: DaVinciApi.IContextualContact): Promise<void> {
    try {
      this.loggerService.logger.logDebug(
        `Channel API ContextualControlCallback:
        contact=${JSON.stringify(contact)}`
      );
      this._connectService.MakeOutboundCall(getContactId(contact));
      return Promise.resolve();
    } catch (e) {
      this.loggerService.logger.logError(
        `Channel API ContextualControlCallback:
        contact=${JSON.stringify(contact)}
        error=${JSON.stringify(e)}`
      );
    }
  }

  setInteraction(interaction: IInteraction) {
    try {
      this.loggerService.logger.logDebug(
        `Channel API setInteraction:
        interaction=${JSON.stringify(interaction)}`
      );
      return DaVinciApi.setInteraction(interaction);
    } catch (e) {
      this.loggerService.logger.logError(
        `Channel API setInteraction:
        interaction=${JSON.stringify(interaction)}
        error=${JSON.stringify(e)}`
      );
    }
  }
}
