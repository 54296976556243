import { CHANNEL_TYPES, ICompletedTranscript, ITranscript } from '@amc-technology/davinci-api';
import { ConnectInteractionOperationsService } from '../connect-interaction-operations.service';
export interface IAction {
  type: ACTION_TYPE;
  number?: string;
  channelType?: CHANNEL_TYPES;
  direction?: DIRECTION;
  uuid: string;
  operations?: ConnectInteractionOperationsService;
  iconPack?: string;
  connections?: any;
  CAD?: any;
  transcripts?: ITranscript;
  completedTranscript?: ICompletedTranscript;
}

export enum DIRECTION {
  inbound = 'Inbound',
  outbound = 'Outbound'
}

export enum ACTION_TYPE {
  started = 'started',
  ringing = 'ringing',
  incomingMsg = 'incomingMsg',
  messageSent = 'messageSent',
  incomingTask = 'incomingTask',
  answered = 'answered',
  completed = 'completed',
  hold = 'hold',
  resume = 'resume',
  noop = 'noop',
  conference = 'conference',
  warmtransfer = 'warmtransfer',
  blindtransfer = 'blindtransfer',
  completedTransfer = 'completedTransfer',
  completedConference = 'completedConference',
  conferencePartyLeft = 'conferencePartyLeft',
  chatConnected = 'chatConnected'
}
