import * as ChannelApi from '@amc-technology/davinci-api';
import { ACTION_TYPE, DIRECTION, IAction } from './redux/action';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { IAppState } from './redux/state';
import { IMessageData } from './model/IMessageData';
import { Injectable } from '@angular/core';
import { LOG_LEVEL } from '@amc-technology/davinci-api';
import { LoggerService } from './logger.service';
import { NgRedux } from '@angular-redux/store';
import { ConnectStorageService } from './connect-storage.service';
import { bind } from 'bind-decorator';

declare let connect: any; // Object to access Amazon Connect API

@Injectable({
  providedIn: 'root'
})
export class ConnectService {
  private log: (logLevel: LOG_LEVEL, fName: string, message: string, object?: any) => void = () => {};

  public static DEFAULT_AWS_REGION = 'us-east-1';

  public presence = new BehaviorSubject<string>('');
  public setSupportedChannels$ = new ReplaySubject<{
    username: string;
    channels?: any;
  }>(1);

  private containerDiv: any;
  public connectionPool: {
    [connId: string]: boolean;
  };

  startOnHold = new Map();
  lastPresence: string;
  outboundCall: string;
  private _agent: any;
  private _configs: any;
  private _popup: any = true;
  private internalTransferEndpoints: [any];
  private contactsPartyList: { [contactId: string]: number } = {};
  private _chatTranscripts: { [contactId: string]: ChannelApi.ITranscript[] } = {}; // Stores transcriptions that are sent to interactions

  constructor(private ngRedux: NgRedux<IAppState>, private loggerService: LoggerService, private storageService: ConnectStorageService) {
    this.connectionPool = {};
    this.log = this.loggerService.log;
    const storageTranscript = JSON.parse(localStorage.getItem('TranscriptMap'));
    if (storageTranscript == null) {
      this.storageService.setTranscriptsToStorage(this._chatTranscripts);
    } else {
      this._chatTranscripts = JSON.parse(localStorage.getItem('TranscriptMap'));
    }
  }

  onhashchange = function () {
    this.startOnHold = JSON.parse(sessionStorage.getItem('holdCount'));
  };

  async StartAmazonConnect(ccpUrl: any, div: any, awsRegion: string): Promise<void> {
    const fName = 'StartAmazonConnect';
    const parameters = {
      ccpUrl,
      div,
      awsRegion
    };
    try {
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'ConnectService starting amazon connect. Parameters: ', parameters);
      if (!awsRegion) {
        this.log(LOG_LEVEL.Critical, `ConnectService ${fName}`, 'AWS Region was not found! Defaulting to ', ConnectService.DEFAULT_AWS_REGION);
        awsRegion = ConnectService.DEFAULT_AWS_REGION;
      }

      ChannelApi.enableClickToDial(true);
      this.containerDiv = div;
      connect.core.initCCP(this.containerDiv, {
        ccpUrl: ccpUrl,
        region: awsRegion,
        loginPopup: false,
        softphone: {
          allowFramedSoftphone: true,
          disableRingtone: false
        }
      });

      this.InitAgent();
      const loginPopup = window.open(ccpUrl, connect.MasterTopics.LOGIN_POPUP);
      connect.contact((contact) => {
        this.log(LOG_LEVEL.Debug, `ConnectService ${fName} callback of connect.contact()`, 'Contact: ', contact);
        this.ContactCallback(contact);
      });
      // 2 lines below detect if we logged out of AmazonConnect
      this.getEventBus().subscribe(connect.EventType.TERMINATED, async () => {
        this.setPopup(false);
        this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Received logout event from Connect, flushing logs and attempting to log out of Framework');
        await this.loggerService.logger.pushLogsAsync();
        ChannelApi.logout();
      });
      this.getEventBus().subscribe(connect.EventType.ACKNOWLEDGE, () => {
        loginPopup.close();
      });

      // The following checks for contacts dropping from conference calls
      window.setInterval(() => {
        const contacts = this._agent.getContacts();
        const contactsPartyList = {};
        let cad = {};
        for (const contact of contacts) {
          const id = contact.getContactId();
          const contactConnections = contact.getConnections().filter((connection) => connection.getStatus().type !== 'disconnected');
          const parties = contactConnections.length;
          contactsPartyList[id] = parties;

          if (this.contactsPartyList[id] != null && this.contactsPartyList[id] > parties) {
            // TODO: How to handle CAD of dropped parties?
            const cadForThisContact = contact.getAttributes();
            cad = Object.assign(cad, cadForThisContact);
            const connections = [];
            for (let index = 0; index < contactConnections.length; index++) {
              if (contactConnections[index].getEndpoint() && contactConnections[index].getEndpoint().type !== 'agent') {
                connections.push({
                  phoneNumber: contactConnections[index].getEndpoint().phoneNumber,
                  connectionId: contactConnections[index].connectionId,
                  status: contactConnections[index].getStatus().type
                });
              }
            }
            const action: IAction = {
              type: ACTION_TYPE.conferencePartyLeft,
              number: connections[0]?.phoneNumber,
              direction: contact.isInbound() ? DIRECTION.inbound : DIRECTION.outbound,
              uuid: id,
              connections,
              CAD: cad
            };
            this.ngRedux.dispatch(action);
          }
        }
        this.contactsPartyList = contactsPartyList;
      }, 500);
    } catch (error) {
      const errorAndParameters = {
        error,
        parameters
      };
      this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Error and Parameters: ', errorAndParameters);
    }
  }

  getEventBus = () => {
    const eventBus = connect.core.getEventBus();
    return eventBus;
  };

  InitAgent() {
    const fName = 'InitAgent';
    connect.agent((agent) => {
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName} connect.agent()`, 'Agent: ', agent);
      const config = agent.getConfiguration();
      this.setSupportedChannels$.next({
        username: config.username,
        channels: config?.routingProfile?.channelConcurrencyMap
      });

      agent.getEndpoints(agent.getAllQueueARNs(), {
        success: (data) => {
          this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'All Queue ARNs: ', data);
          let internalTransfers: [Object];
          if (Array.isArray(data.endpoints)) {
            internalTransfers = data.endpoints.filter((ep) => ep.type === 'agent');
          }
          this.internalTransferEndpoints = internalTransfers;
        },
        failure: () => {
          this.log(LOG_LEVEL.Error, `ConnectService ${fName} agent.getEndPoints()`, 'Failed getting all queue ARNs');
        }
      });

      this.presence.next(agent.getState().name);
      agent.onStateChange((state) => {
        this.log(LOG_LEVEL.Debug, `ConnectService ${fName} agent.onStateChange()`, 'state: ', state);
        this.presence.next(state.newState);
      });
      this._agent = agent;
    });
  }

  async setAppConfig(configs) {
    const fName = 'setAppConfig';
    this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Configs: ', configs);
    this._configs = configs;
  }

  getAppConfig() {
    return this._configs;
  }

  setPopup(popup: boolean) {
    const fName = 'setPopup';
    this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Popup: ', popup);
    this._popup = popup;
  }

  getPopup() {
    return this._popup;
  }

  async initLogoutFromConnect() {
    this.log(LOG_LEVEL.Debug, 'ConnectService', 'initLogoutFromConnect');
    const logoutLink = this._configs.LogoutLink;
    const popupConfig = 'height=1, width=1, status=no, toolbar=no, menubar=no, location=no, top = 100000, left=100000 ';
    return await window.self.open(logoutLink, 'Popup', popupConfig);
  }

  setPresence(presenceName: string) {
    const fName = 'setPresence';
    return new Promise<void>((resolve, reject) => {
      const newState = this._agent.getAgentStates().find((state) => state.name === presenceName);
      this._agent.setState(newState, {
        success: () => {
          this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Success');
          resolve();
        },
        failure: (error) => {
          this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Error: ', error);
        }
      });
    });
  }

  MakeOutboundCall(dialNumber: string) {
    const fName = 'MakeOutboundCall';
    const endpoint = this.getEndpoint(dialNumber);
    this._agent.connect(endpoint, {
      success: () => {
        this.loggerService.logger.logDebug(
          `ConnectService ${fName} successful
          dialNumber=${dialNumber}`
        );
      },
      failure: (e) => {
        this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Error: ', e);
        let objErr;
        try {
          objErr = JSON.parse(e);
        } catch (err) {
          this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Parsing Error: ', err);
        }

        ChannelApi.sendNotification(
          `Outbound call failed. ${dialNumber} was entered, ${endpoint?.phoneNumber || JSON.stringify(endpoint, null, 2)} was dialed. Additional Info: ${objErr?.message || (typeof e == 'string' ? e : JSON.stringify(e, null, 2))}`,
          ChannelApi.NOTIFICATION_TYPE.Error
        );
        this.loggerService.logger.logError(
          `ConnectService ${fName}:
          dialNumber=${dialNumber}
          error=${JSON.stringify(e)}`
        );
      }
    });
  }

  // eslint-disable-next-line max-statements
  async ContactCallback(contact: any) {
    const fName = 'ContactCallback';
    try {
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Contact: ', contact);
      this.loggerService.logger.logDebug(
        `ConnectService ${fName} received new contact
        contactId=${contact.getContactId()}
        type=${contact.getType()}
        status=${JSON.stringify(contact.getStatus())}
        isSoftphoneCall=${contact.isSoftphoneCall()}
        isInbound=${contact.isInbound()}
        attributes=${JSON.stringify(contact.getAttributes())}`
      );

      // A queued callback will be treated like a normal inbound or outbound voice call
      if ((contact.isSoftphoneCall() || contact.getType() === 'queue_callback') && contact.getStatus().type !== 'error') {
        const name = contact.getAttributes();
        const conns = contact.getConnections();
        if (contact.getStatus().type === 'ended') {
          conns.destroy();
        }
        // onEnded() event gets trigged when the call ends. This happens exactly once per contact.
        contact.onEnded(() => {
          this.log(LOG_LEVEL.Debug, `ConnectService ${fName} queued callback`, 'Contact when call ended: ', contact);
          // When a queued callback is accepted, it will send a disconnect event before
          // starting a new outbound call (with the same scenario ID).
          // The disconnected queued callback will have a "pending" status. If this status
          // is detected, the disconnect event will be suppressed so that the CRM app does not
          // close the activity and screenpop again when the outbound call is made.

          // When a queued callback is rejected, its status will be 'error'. As long as the
          // status of a queued callback is not 'pending', the disconnect event will be sent.
          if (contact.getType() === 'queue_callback' && contact.getStatus().type === 'pending') {
            return;
          }
          const connections = contact.getConnections();
          const endpoint = connections[1].getEndpoint();
          const phonenumber = endpoint.stripPhoneNumber().substring(1);
          const action: IAction = {
            type: ACTION_TYPE.completed,
            uuid: contact.getContactId(),
            number: phonenumber,
            CAD: name
          };
          this.ngRedux.dispatch(action);
          // Here we clear the map and update session storage;
          this.startOnHold.delete(contact.contactId);
          sessionStorage.setItem('holdCount', JSON.stringify(Array.from(this.startOnHold.entries())));
        });
        // TODO: Clean up of conference parties here instead of setInterval used above
        // onRefresh() event gets trigged every time there's a change in the call.
        contact.onRefresh(() => {
          this.log(LOG_LEVEL.Debug, `ConnectService ${fName} queued callback`, 'Contact when call refreshed: ', contact);
          // Here we are checking if the Client's connection is on hold - AC always create 2 connections: 1 for agent 1 for client
          const onHold = contact.getInitialConnection().isOnHold();
          // If the client connection is on hold we update our Map value (key: contactID, value: isOnHold tracking variable) and
          // save it to session storage
          if (onHold) {
            this.startOnHold.set(contact.contactId, this.startOnHold.get(contact.contactId) + 1 || 1);
            sessionStorage.setItem('holdCount', JSON.stringify(Array.from(this.startOnHold.entries())));
            // If the map value is equal to 1: This is the first time we get a True value from onHold
            // Then we update the action to ACTION_TYPE.hold, which will trigger another function to send an onHold interaction to the CRM
            // If the map value is greater than 1 we do NOTHING.
            if (this.startOnHold.get(contact.contactId) === 1) {
              const connections = contact.getConnections();
              const endpoint = connections[1].getEndpoint();
              const phonenumber = endpoint.stripPhoneNumber().substring(1);
              const action: IAction = {
                type: ACTION_TYPE.hold,
                uuid: contact.getContactId(),
                number: phonenumber,
                CAD: name
              };
              this.ngRedux.dispatch(action);
            }
            // If onHold is FALSE - we need to check if it's due to a call resume or not
            // If the map value is a number greater than 0 it means the call was put on hold and this is the first time we're getting
            // the FALSE onHold, so we update Action to send a reume interaction to the CRM
            // If the map value is 0 it means the call was never put on hold.
          } else {
            if (this.startOnHold.get(contact.contactId) > 0) {
              const connections = contact.getConnections();
              const endpoint = connections[1].getEndpoint();
              const phonenumber = endpoint.stripPhoneNumber().substring(1);
              const action: IAction = {
                type: ACTION_TYPE.resume,
                uuid: contact.getContactId(),
                number: phonenumber,
                CAD: name
              };
              this.ngRedux.dispatch(action);
              this.startOnHold.set(contact.contactId, 0);
              sessionStorage.setItem('holdCount', JSON.stringify(Array.from(this.startOnHold.entries())));
            }
          }
        });
        // Queued callback calls appear as 'Inbound' calls when the initial preview is received
        // by the agent. The condition below ensures that queued callbacks are treated as outbound calls.
        if (contact.isInbound() && contact.getType() !== 'queue_callback') {
          // Before a call gets processed we initialize the startOnHold map - the 0 will only get updated if the call is put on hold
          this.startOnHold.set(contact.contactId, 0);
          this.ProcessInboundCall(contact, name, conns);
        } else {
          this.startOnHold.set(contact.contactId, 0);
          this.ProcessOutboundCall(contact, name, conns);
        }
      }
      if (contact.getType() === connect.ContactType.CHAT && contact.getStatus().type !== 'error') {
        // We only want to call ProcessSMS or ProcessChat if the contact has not been accepted yet
        if (contact.getStatus().type === 'ended') {
          try {
            const attributesTest = contact.getAgentConnection();
            const chatSession = await attributesTest.getMediaController();
            // Note: Probably cannot retrieve messages sent before disconnect tbh. No way to get media controller
            this.isMessagesBeforeChatEstablished(chatSession);
          } catch (e) {
            // This error code is to clean up the following failure scenario:
            // Agent refreshes page and the customer ends the chat.
            this.loggerService.logger.logError(
              `ConnectService ${fName}:
              Agent connection does not exist, cleaning chat : 
              contactId=${contact.getContactId()}
              error=${JSON.stringify(e)}`
            );
            const completedTranscript: ChannelApi.ICompletedTranscript = this.createCompleteTranscription(contact.contactId);
            const action: IAction = {
              type: ACTION_TYPE.completed,
              uuid: contact.contactId,
              channelType: ChannelApi.CHANNEL_TYPES.Chat,
              direction: DIRECTION.inbound,
              CAD: contact.getAttributes(),
              completedTranscript: completedTranscript
            };
            delete this._chatTranscripts[contact.contactId];
            this.storageService.setTranscriptsToStorage(this._chatTranscripts);
            this.ngRedux.dispatch(action);
          }
        }
        if (contact.getStatus().type === 'connecting') {
          const contactAttributes = contact.getAttributes();
          // For either chat or SMS, this results in the interaction sent to the CRM for a chat session
          if (contactAttributes.chatframework_Channel) {
            const phone = contactAttributes.chatframework_VendorId.value;
            this.ProcessSMS(contact.contactId, contactAttributes, phone);
          } else {
            this.ProcessChat(contact.contactId, contactAttributes);
          }
        }
        // Subscribing to the event for when a contact ends | happens once per contact
        contact.onEnded(this.closeChatHandler);
        // Subscribing to the event for when/if a contact is connected | happens after accepting the chat or when refreshing the page
        contact.onConnected(this.establishChatSessionConnection);
      }
      if (contact.getType() === 'task' && contact.getStatus().type !== 'error') {
        const name = contact.getAttributes();
        const conns = contact.getConnections();
        if (contact.getStatus().type === 'ended') {
          conns.destroy();
        }
        const action: IAction = {
          type: ACTION_TYPE.incomingTask,
          uuid: contact.contactId,
          direction: DIRECTION.inbound,
          CAD: name
        };
        this.ngRedux.dispatch(action);

        contact.onEnded(() => {
          const action1: IAction = {
            type: ACTION_TYPE.completed,
            uuid: contact.contactId,
            direction: DIRECTION.inbound,
            CAD: name
          };
          this.ngRedux.dispatch(action1);
        });
      }
    } catch (e) {
      this.loggerService.logger.logError(
        `ConnectService ${fName}:
        contactId=${contact.getContactId()}
        error=${JSON.stringify(e)}`
      );
    }
  }

  /**
   * Returns the agent's contact object based on the contactId.
   * @param contactId ID of the contact that we want to retrieve
   * @returns the contact object that matches the contactId
   */
  private getContactbyId(contactId: string): any {
    const fName = 'getContactbyId';
    try {
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Contact ID: ', contactId);
      return this._agent.getContacts().find((contact) => contact.getContactId() === contactId);
    } catch (error) {
      this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Error: ', error);
    }
  }

  /**
   * This method dispatches an action which starts the first interaction of a chat session if a phone number exists.
   * @param contactId id of the contact for the incoming chat
   * @param phone phone number of the contact
   * @param cadData map of attributes associated with the contact, each map has the following form: {name: string, value: string}
   */
  private ProcessSMS(contactId: string, cadData: any, phone: string) {
    const fName = 'ProcessSMS';
    const parameters = {
      contactId,
      phone,
      cadData
    };
    try {
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Parameters: ', parameters);
      const action: IAction = {
        type: ACTION_TYPE.incomingMsg,
        uuid: contactId,
        number: phone,
        direction: DIRECTION.inbound,
        CAD: cadData
      };
      this.ngRedux.dispatch(action);
    } catch (error) {
      const errorAndParameters = {
        error,
        parameters
      };
      this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Error and Parameters: ', errorAndParameters);
    }
  }

  /**
   * This method dispatches an action which starts the first interaction of a chat session if no phone number exists.
   * @param contactId id of the contact for the incoming chat
   * @param cadData map of attributes associated with the contact, each map has the following form: {name: string, value: string}
   */
  private ProcessChat(contactId: string, cadData: any) {
    const fName = 'ProcessChat';
    const parameters = {
      contactId,
      cadData
    };
    try {
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Parameters: ', parameters);
      const action: IAction = {
        type: ACTION_TYPE.incomingMsg,
        uuid: contactId,
        direction: DIRECTION.inbound,
        CAD: cadData
      };
      this.ngRedux.dispatch(action);
    } catch (error) {
      const errorAndParameters = {
        error,
        parameters
      };
      this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Error and Parameters: ', errorAndParameters);
    }
  }

  /**
   * Called once the agent accepts a contact.
   * Responsible for estabslishing the agent's connection to the chat session.
   * This allows for transcription functionality to begin.
   * @param contact the contact object accepted by the agent
   */
  @bind
  private async establishChatSessionConnection(contact: any) {
    const fName = 'establishChatSessionConnection';
    try {
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Contact: ', contact);
      if (!this._chatTranscripts[contact.contactId]) {
        this._chatTranscripts[contact.contactId] = [];
        this.storageService.setTranscriptsToStorage(this._chatTranscripts);
      }
      const connection = contact.getAgentConnection();
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Agent Connection: ', connection);
      if (connection.getMediaType() === connect.MediaType.CHAT) {
        const chatSession = await connection.getMediaController();
        this.beginTranscribingChatSession(chatSession);
        const contactAttributes = contact.getAttributes();
        const action: IAction = {
          type: ACTION_TYPE.chatConnected,
          uuid: contact.contactId,
          direction: DIRECTION.inbound,
          CAD: contactAttributes
        };
        this.ngRedux.dispatch(action);
      } else {
        this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, `This connection's media type is not chat: `, connection.getMediaType());
      }
    } catch (error) {
      const errorAndParameters = { error, contact };
      this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Error and Parameters: ', errorAndParameters);
    }
  }

  private async isMessagesBeforeChatEstablished(chatSession: any) {
    const fName = 'isMessagesBeforeChatEstablished';
    try {
      const contactId: string = chatSession.controller.contactId;
      const requestBody = {
        contactId: contactId,
        maxResults: 100,
        sortOrder: 'DESCENDING',
        nextToken: ''
      };
      const newMessages = [];
      let foundLastTranscript = false;
      let lastTranscriptId = '';
      if (this._chatTranscripts[contactId].length > 0) {
        lastTranscriptId = this._chatTranscripts[contactId][this._chatTranscripts[contactId].length - 1].id;
      }
      // Retrieve all messages sent before the agent connects/reconnects to the chat
      do {
        const transcriptEvent = await chatSession.getTranscript(requestBody);
        requestBody.nextToken = transcriptEvent.data.NextToken;
        const transcripts: IMessageData[] = transcriptEvent.data.Transcript;
        this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Transcript: ', transcriptEvent);
        if (foundLastTranscript === false) {
          for (const messageData of transcripts) {
            if (this._chatTranscripts[contactId].length > 0 && messageData.Id !== lastTranscriptId) {
              newMessages.push(messageData);
            } else if (this._chatTranscripts[contactId].length > 0 && messageData.Id === lastTranscriptId) {
              foundLastTranscript = true;
              break;
            } else {
              if (messageData.Content && messageData.ParticipantRole !== 'SYSTEM') {
                newMessages.push(messageData);
              }
            }
          }
        }
      } while (requestBody.nextToken);
      for (let i = newMessages.length - 1; i >= 0; i--) {
        const chatTranscript = this.convertChatMessageToTranscript(newMessages[i]);
        this._chatTranscripts[contactId].push(chatTranscript);
        this.newTranscriptInteraction(chatTranscript, contactId);
      }
      this.storageService.setTranscriptsToStorage(this._chatTranscripts);
    } catch (error) {
      const errorAndParameters = { error, chatSession };
      this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Error and Parameters: ', errorAndParameters);
    }
  }

  /**
   * Get all messages sent before the agent accepts the chat and begin listening for new messages.
   * @param chatSession the chat session object that grants access to chat messages for a given contact
   */
  @bind
  private async beginTranscribingChatSession(chatSession: any) {
    const fName = 'beginTranscribingChatSession';
    try {
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Chat Session: ', chatSession);
      // Could await this but this information really isn't required to be awaited for the rest of the function
      this.isMessagesBeforeChatEstablished(chatSession);
      chatSession.onMessage(this.handleNewChatMessage); // Begin listening for new messages from either agent or customer
    } catch (error) {
      const errorAndParameters = { error, chatSession };
      this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Error and Parameters: ', errorAndParameters);
    }
  }

  /**
   * When a new message is received, this method does the following:
   * Convert message data into a Transcript object
   * Append Transcript object it to the chatTranscript object based on the contact ID
   * Send the Transcript object to the CRM as part of a new interaction
   * @param event the event object that contains the message data
   */
  @bind
  private handleNewChatMessage(event: any) {
    const fName = 'handleNewChatMessage';
    try {
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Parameters - only Chat Session Message event: ', event);
      const messageData: IMessageData = event.data;
      if (messageData.Content) {
        const chatTranscript = this.convertChatMessageToTranscript(messageData);
        this._chatTranscripts[messageData.ContactId].push(chatTranscript);
        this.storageService.setTranscriptsToStorage(this._chatTranscripts);
        this.newTranscriptInteraction(chatTranscript, messageData.ContactId); // Send the transcript to a new interaction
      }
    } catch (error) {
      const errorAndParameters = { error, event };
      this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Error and Parameters: ', errorAndParameters);
    }
  }

  /**
   * Converts message data from the chat session into a Transcript object to be included with an interaction
   * @param messageData object obtained using the chatSession object
   * @returns a Transcript object that will be sent as part of an interaction
   */
  private convertChatMessageToTranscript(messageData: IMessageData): ChannelApi.ITranscript {
    const fName = 'convertChatMessageToTranscript';
    try {
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Parameters - only Message Data: ', messageData);
      const userDetails: ChannelApi.IUserDetails = {
        email: messageData.DisplayName,
        username: messageData.DisplayName,
        firstName: messageData.DisplayName,
        lastName: messageData.DisplayName,
        attributes: messageData.ParticipantRole,
        profiles: [
          {
            profileName: messageData.ParticipantRole,
            profileid: messageData.ParticipantRole,
            userid: messageData.ParticipantId
          }
        ]
      };
      const transcript: ChannelApi.ITranscript = {
        id: messageData.Id,
        data: messageData.Content,
        isComplete: true,
        context: userDetails,
        timestamp: new Date(messageData.AbsoluteTime)
      };
      return transcript;
    } catch (error) {
      const errorAndParameters = { error, messageData };
      this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Error and Parameters: ', errorAndParameters);
    }
    return null;
  }

  /**
   * Sends the most recent transcript to create a new interaction
   * @param transcript The most recent message received from the chat session - can be from either the agent or the customer
   * @param contactId The contact ID associated with the chat session
   */
  private newTranscriptInteraction(transcript: ChannelApi.ITranscript, contactId: string) {
    const fName = 'newTranscriptInteraction';
    const parameters = {
      transcript,
      contactId
    };
    try {
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Parameters: ', parameters);
      const contact = this.getContactbyId(contactId);
      const contactAttributes = contact.getAttributes();
      // For either chat or SMS, this results in the interaction sent to the CRM for a chat session
      if (contactAttributes.chatframework_Channel) {
        const phone = contactAttributes.chatframework_VendorId.value;
        const action: IAction = {
          type: ACTION_TYPE.messageSent,
          uuid: contactId,
          number: phone,
          channelType: ChannelApi.CHANNEL_TYPES.SMS,
          direction: DIRECTION.inbound,
          CAD: contactAttributes,
          transcripts: transcript
        };
        this.ngRedux.dispatch(action);
      } else {
        const action: IAction = {
          type: ACTION_TYPE.messageSent,
          uuid: contactId,
          channelType: ChannelApi.CHANNEL_TYPES.Chat,
          direction: DIRECTION.inbound,
          CAD: contactAttributes,
          transcripts: transcript
        };
        this.ngRedux.dispatch(action);
      }
    } catch (error) {
      const errorAndParameters = { error, parameters };
      this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Error and Parameters: ', errorAndParameters);
    }
  }

  /**
   * Called when a chat has ended.
   * Responsible for cleaning up the chat session and sending the completed transcript to the CRM.
   * If there are no transcripts, then a completed interaction is sent without any transcriptions.
   * @param contact the contact object that is ended
   */
  @bind
  private async closeChatHandler(contact: any) {
    const fName = 'closeChatHandler';
    try {
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Parameters - only Contact: ', contact);
      if (this._chatTranscripts[contact.contactId]) {
        const connection = contact.getAgentConnection();
        if (connection.getMediaType() === connect.MediaType.CHAT) {
          const chatSession = await connection.getMediaController();
          this.cleanUpChatSession(chatSession);
        }
        // Convert messages into a CompletedTranscript
        const completedTranscript: ChannelApi.ICompletedTranscript = this.createCompleteTranscription(contact.contactId);
        // The action is included with the interaction that is sent to the CRM
        const action: IAction = {
          type: ACTION_TYPE.completed,
          uuid: contact.contactId,
          channelType: ChannelApi.CHANNEL_TYPES.Chat,
          direction: DIRECTION.inbound,
          CAD: contact.getAttributes(),
          completedTranscript: completedTranscript
        };
        delete this._chatTranscripts[contact.contactId]; // Clear out the contact and its messages from the chatMessages object
        this.storageService.setTranscriptsToStorage(this._chatTranscripts);
        this.ngRedux.dispatch(action); // This action is sent to be used by an interaction
      } else {
        // The contact never had messages, so we send a completed interaction without a transcript
        this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'No chat messages found for contact: ', contact);
        const action: IAction = {
          type: ACTION_TYPE.completed,
          uuid: contact.contactId,
          channelType: ChannelApi.CHANNEL_TYPES.Chat,
          direction: DIRECTION.inbound,
          CAD: contact.getAttributes()
        };
        this.ngRedux.dispatch(action); // This action is sent to be used by an interaction
      }
    } catch (error) {
      const errorAndParameters = { error, contact };
      this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Error and Parameters: ', errorAndParameters);
    }
  }

  /**
   * Cleans up event listeners associated with a chat session
   * @param chatSession the chat session object that is being cleaned up
   */
  @bind
  private cleanUpChatSession(chatSession: any) {
    const fName = 'cleanUpChatSession';
    try {
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Parameters - only Chat Session: ', chatSession);
      chatSession.cleanUpOnParticipantDisconnect(); // Cleans up event listeners of the agent's chatSession
    } catch (error) {
      const errorAndParameters = { error, chatSession };
      this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Error and Parameters: ', errorAndParameters);
    }
  }

  /**
   * Compiles all Transcript objects to create a CompletedTranscript object that is included with the disconnect interaction
   * @param contactId the contact ID of the chat session that has ended
   * @returns A CompletedTranscript object that is included with the disconnect interaction
   */
  private createCompleteTranscription(contactId: string): ChannelApi.ICompletedTranscript {
    const fName = 'createCompleteTranscription';
    try {
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Contact ID: ', contactId);
      const chatTranscripts = this._chatTranscripts[contactId];
      const analytics: ChannelApi.IAnalytics[] = [];
      const completedTranscript: ChannelApi.ICompletedTranscript = {
        id: contactId,
        messages: chatTranscripts,
        analytics: analytics,
        startTimestamp: chatTranscripts[0].timestamp,
        endTimestamp: chatTranscripts[chatTranscripts.length - 1].timestamp
      };
      return completedTranscript;
    } catch (error) {
      const errorAndParameters = { error, contactId };
      this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Error and Parameters: ', errorAndParameters);
      return null;
    }
  }

  ProcessOutboundCall(contact: any, name: any, conns: any) {
    const fName = 'ProcessOutboundCall';
    const parameters = {
      contact,
      name,
      conns
    };
    try {
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Parameters: ', parameters);
      const endpoint = conns[1].getEndpoint();
      const phonenumber = endpoint.stripPhoneNumber().substring(1);
      this.outboundCall = contact.getContactId();
      this.loggerService.logger.logDebug(
        `ConnectService ${fName}: phone number found
        contactId=${contact.getContactId()}
        phoneNumber=${phonenumber}`
      );
      const action: IAction = {
        type: ACTION_TYPE.ringing,
        number: phonenumber,
        direction: DIRECTION.outbound,
        uuid: this.outboundCall,
        connections: [{ connectionId: contact.getConnections()[1].connectionId }],
        CAD: name
      };
      this.ngRedux.dispatch(action);
      contact.onConnected(() => this.OnConnectedCallback(phonenumber, DIRECTION.outbound, this.outboundCall, contact.getConnections()[1].connectionId, name));
    } catch (error) {
      const errorAndParameters = { error, parameters };
      this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Error and Parameters: ', errorAndParameters);
    }
  }

  OnConnectedCallback(phonenumber: string, direction: DIRECTION, callId: string, connectionId?: string, cad?: any) {
    const fName = 'OnConnectedCallback';
    const parameters = {
      phonenumber,
      direction,
      callId,
      connectionId,
      cad
    };
    try {
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Parameters: ', parameters);
      const action: IAction = {
        type: ACTION_TYPE.answered,
        number: phonenumber,
        direction: direction,
        uuid: callId,
        connections: [{ connectionId: connectionId }]
      };

      if (cad) {
        action.CAD = cad;
      }

      this.ngRedux.dispatch(action);
    } catch (error) {
      const errorAndParameters = { error, parameters };
      this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Error and Parameters: ', errorAndParameters);
    }
  }

  async conferenceCall(contactId?: string) {
    const fName = 'conferenceCall';
    try {
      if (contactId) {
        this._agent.getContacts().forEach((contact) => {
          if (contact.contactId === contactId) {
            contact.conferenceConnections({
              success: () => {
                this.log(LOG_LEVEL.Debug, `ConnectService ${fName} contact.conferenceConnections success`, 'Contact: ', contact);
                const cad = contact.getAttributes();
                const agentConns = contact.getConnections();
                const connections = [];
                for (let index = 0; index < agentConns.length; index++) {
                  if (agentConns[index].getEndpoint() && agentConns[index].getEndpoint().type !== 'agent') {
                    connections.push({
                      phoneNumber: agentConns[index].getEndpoint().phoneNumber,
                      connectionId: agentConns[index].connectionId
                    });
                  }
                }
                const action: IAction = {
                  type: ACTION_TYPE.completedConference,
                  uuid: contact.getContactId(),
                  connections: connections,
                  CAD: cad
                };
                this.ngRedux.dispatch(action);
              },
              failure: () => {
                this.log(LOG_LEVEL.Error, `ConnectService ${fName} contact.conferenceConnections() failure`, 'Contact: ', contact);
              }
            });
          }
        });
      } else {
        let cad = {};
        this._agent.getContacts().forEach((contact) => {
          this.log(LOG_LEVEL.Debug, `ConnectService ${fName} this._agent.getContacts()`, 'Contact: ', contact);
          contact.conferenceConnections({
            success: () => {
              cad = Object.assign(cad, contact.getAttributes());
              const agentConns = contact.getConnections();
              const connections = [];
              for (let index = 0; index < agentConns.length; index++) {
                if (agentConns[index].getEndpoint() && agentConns[index].getEndpoint().type !== 'agent') {
                  connections.push({
                    phoneNumber: agentConns[index].getEndpoint().phoneNumber,
                    connectionId: agentConns[index].connectionId
                  });
                }
              }
              const action2: IAction = {
                type: ACTION_TYPE.completedConference,
                uuid: contact.getContactId(),
                connections: connections,
                CAD: cad
              };
              this.ngRedux.dispatch(action2);
            },
            failure: () => {
              this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'error');
            }
          });
        });
      }
    } catch (error) {
      this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Error: ', error);
    }
  }

  async completeWarmTransfer() {
    const fName = 'completeWarmTransfer';
    this._agent.getContacts().forEach((contact) => {
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Contact: ', contact);
      contact.conferenceConnections({
        success: () => {
          contact.getAgentConnection().destroy({
            success: () => {
              const action: IAction = {
                type: ACTION_TYPE.completedTransfer,
                uuid: contact.getContactId()
              };
              this.ngRedux.dispatch(action);
            },
            failure: () => {
              this.log(LOG_LEVEL.Error, `ConnectService ${fName} contact.getAgentConnection().destroy()`, 'Failed to end the call!');
            }
          });
        },
        failure: () => {
          this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'error');
        }
      });
    });
  }

  async warmTransferCall(phoneNumber: string, transferType: string = '', contactId?: string) {
    const fName = 'warmTranferCall';
    try {
      const getOurAvailableContacts = this._agent.getContacts();
      let ourContactId;
      if (contactId) {
        ourContactId = contactId;
      } else {
        ourContactId = this._agent.getContacts().find((aContact) => aContact.getContactId());
      }
      let oContactId;
      let trueUUID;
      getOurAvailableContacts.forEach((contact) => {
        this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Contact: ', contact);
        trueUUID = contact.getConnections()[0].getConnectionId();
        oContactId = { contactId: contact.contactId };
        if (JSON.stringify(oContactId) === JSON.stringify(ourContactId)) {
          const endpoint = this.getEndpoint(phoneNumber);

          const trueUUID2 = contact.getConnections()[0].getContactId();
          this.HoldCall(trueUUID2);

          this._agent.getContacts(connect.ContactType.VOICE)[0].addConnection(endpoint, {
            success: (resp) => {
              this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Success: ', resp);
              const bus = this.getEventBus();
              bus.subscribe(contact.getEventName(connect.ContactEvents.REFRESH), () => {
                if (contact) {
                  const cad = contact.getAttributes();
                  const connections = contact.getConnections();
                  const outboundConnection = connections[connections.length - 1];
                  if (!this.connectionPool[outboundConnection.getConnectionId()]) {
                    this.connectionPool[outboundConnection.getConnectionId()] = false;
                  }
                  if (outboundConnection && outboundConnection.isConnected() && !this.connectionPool[outboundConnection.getConnectionId()]) {
                    this.connectionPool[outboundConnection.getConnectionId()] = true;
                    const action: IAction = {
                      type: transferType === 'conference' ? ACTION_TYPE.conference : ACTION_TYPE.warmtransfer,
                      number: phoneNumber,
                      direction: DIRECTION.outbound,
                      uuid: outboundConnection.getConnectionId(),
                      connections: [{ connectionId: outboundConnection.getConnectionId() }],
                      CAD: cad
                    };
                    this.ngRedux.dispatch(action);
                  } else if (outboundConnection && !outboundConnection.isActive() && this.connectionPool[outboundConnection.getConnectionId()]) {
                    delete this.connectionPool[outboundConnection.getConnectionId()];
                  }
                }
              });
            },
            failure: () => {
              this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Failed to add connection (transfer)!');
            }
          });
        }
      });
    } catch (error) {
      this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Error: ', error);
    }
  }

  async coldTransferCall(phoneNumber: string) {
    const fName = 'coldTransferCall';
    this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Phone Number: ', phoneNumber);
    const getContactsFromAmazon = this._agent.getContacts();
    const contactId = this._agent.getContacts().find((aContact) => aContact.getContactId());
    let oContactId;
    getContactsFromAmazon.forEach((contact) => {
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Contact: ', contact);
      oContactId = { contactId: contact.contactId };
      if (JSON.stringify(oContactId) === JSON.stringify(contactId)) {
        const endpoint = this.getEndpoint(phoneNumber);
        contact.addConnection(endpoint, {
          success: () => {
            this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, '+ added external connection');
            contact.getAgentConnection().destroy({
              success: () => {
                this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'ended agent connection on successful transfer');
              },
              failure: () => {
                this.log(LOG_LEVEL.Error, `ConnectService ${fName} getAgentConnection().destroy()`, 'Failed to end the call!');
              }
            });
          },
          failure: () => {
            this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Failed to add connection (transfer)!');
          }
        });
      }
    });
  }

  ProcessInboundCall(contact: any, cad: any, conns: any) {
    const fName = 'ProcessInboundCall';
    const parameters = {
      contact,
      cad,
      conns
    };
    try {
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Parameters: ', parameters);
      const endpoint = conns[1].getEndpoint();
      const phonenumber = endpoint.stripPhoneNumber().substring(1);
      this.loggerService.logger.logDebug(
        `ConnectService ${fName}: phone number found
      contactId=${contact.getContactId()}
      phoneNumber=${phonenumber}`
      );
      const action: IAction = {
        type: ACTION_TYPE.ringing,
        number: phonenumber,
        direction: DIRECTION.inbound,
        uuid: contact.getContactId(),
        CAD: cad
      };
      this.ngRedux.dispatch(action);

      contact.onConnected(() => this.OnConnectedCallback(phonenumber, DIRECTION.inbound, action.uuid, undefined, cad));
    } catch (error) {
      const errorAndParameters = { error, parameters };
      this.log(LOG_LEVEL.Error, `ConnectService ${fName}`, 'Error and Parameters: ', errorAndParameters);
    }
  }

  EndCall(contactId: string, connectionId?: string) {
    const fName = 'EndCall';
    try {
      this.loggerService.logger.logDebug(
        `ConnectService ${fName}: ending call
        contactId=${contactId}`
      );
      const contact = this._agent.getContacts().find((aContact) => aContact.contactId === contactId);
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Contact: ', contact);
      if (contact) {
        if (connectionId) {
          contact.getConnections().forEach((element) => {
            this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Element: ', element);
            if (connectionId === element.getConnectionId()) {
              // element.destroy();
              contact.getInitialConnection().destroy();
              const action: IAction = {
                type: ACTION_TYPE.completedTransfer,
                uuid: connectionId
              };
              // this.ngRedux.dispatch(action);
            }
          });
        } else {
          contact.getConnections().forEach((element) => {
            this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Element: ', element);
            element.destroy();
          });
          contact.destroy();
        }
      }
    } catch (e) {
      this.loggerService.logger.logError(
        `ConnectService EndCall:
        contactId=${contactId}
        error=${JSON.stringify(e)}`
      );
    }
  }

  AnswerCall(contactId: string) {
    const fName = 'AnswerCall';
    try {
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Contact ID: ', contactId);
      const contact = this._agent.getContacts().find((aContact) => aContact.contactId === contactId);
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Contact: ', contact);
      contact.accept({
        success: () => {
          this.loggerService.logger.logDebug(
            `ConnectService ${fName}: successfully answered call
          contactId=${contactId}`
          );
        },
        failure: (e) => {
          this.loggerService.logger.logError(
            `ConnectService ${fName}: failed to answer call
          contactId=${contactId}
          error=${JSON.stringify(e)}`
          );
        }
      });
    } catch (e) {
      this.loggerService.logger.logError(
        `ConnectService ${fName}:
      contactId=${contactId}
      error=${JSON.stringify(e)}`
      );
    }
  }

  HoldCall(contactId: string) {
    const fName = 'HoldCall';
    try {
      this.loggerService.logger.logDebug(
        `ConnectService ${fName}: putting call on hold
      contactId=${contactId}`
      );
      const contact = this._agent.getContacts().find((aContact) => aContact.contactId === contactId);
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Contact: ', contact);
      if (contact) {
        for (const connection of contact.getConnections()) {
          connection.hold({
            success: () => {
              this.loggerService.logger.logDebug(
                `ConnectService ${fName}: successfully put call on hold
              contactId=${contactId}`
              );
            },
            failure: (e) => {
              this.loggerService.logger.logError(
                `ConnectService ${fName}: failed to put call on hold
              contactId=${contactId}
              error=${JSON.stringify(e)}`
              );
            }
          });
        }
        const cad = contact.getAttributes();
        const conns = contact.getConnections();
        const endpoint = conns[1].getEndpoint();
        const phonenumber = endpoint.stripPhoneNumber().substring(1);

        const action: IAction = {
          type: ACTION_TYPE.hold,
          uuid: contactId,
          number: phonenumber,
          CAD: cad
        };
        this.ngRedux.dispatch(action);
      }
    } catch (e) {
      this.loggerService.logger.logError(
        `ConnectService ${fName}:
        contactId=${contactId}
        error=${JSON.stringify(e)}`
      );
    }
  }

  ResumeCall(contactId: string) {
    const fName = 'ResumeCall';
    try {
      this.loggerService.logger.logDebug(
        `ConnectService ${fName}: resuming call
      contactId=${contactId}`
      );
      const contact = this._agent.getContacts().find((aContact) => aContact.contactId === contactId);
      this.log(LOG_LEVEL.Debug, `ConnectService ${fName}`, 'Contact: ', contact);
      if (contact) {
        for (const connection of contact.getConnections()) {
          connection.resume({
            success: () => {
              this.loggerService.logger.logDebug(
                `ConnectService ${fName}: successfully resumed call
            contactId=${contactId}`
              );
            },
            failure: (e) => {
              this.loggerService.logger.logError(
                `ConnectService ${fName}: failed to resume call
            contactId=${contactId}
            error=${JSON.stringify(e)}`
              );
            }
          });
        }
        const cad = contact.getAttributes();
        const conns = contact.getConnections();
        const endpoint = conns[1].getEndpoint();
        const phonenumber = endpoint.stripPhoneNumber().substring(1);
        const action: IAction = {
          type: ACTION_TYPE.resume,
          uuid: contactId,
          number: phonenumber,
          CAD: cad
        };
        this.ngRedux.dispatch(action);
      }
    } catch (e) {
      this.loggerService.logger.logError(
        `ConnectService ${fName}:
      contactId=${contactId}
      error=${JSON.stringify(e)}`
      );
    }
  }

  getEndpoint(toCall: string) {
    let endpoint = this.internalTransferEndpoints?.find((anEndpoint) => anEndpoint.name === toCall);
    if (endpoint == null) {
      endpoint = connect.Endpoint.byPhoneNumber(
        // Remove all characters except for standard DTMF digits and the + character
        toCall.replace(/[^0-9\*#\+]/g, '')
      );
    }
    return endpoint;
  }
}
