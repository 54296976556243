import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { ITranscript, LOG_LEVEL } from '@amc-technology/davinci-api';

@Injectable({
  providedIn: 'root'
})
export class ConnectStorageService {
  public transcriptMap;
  constructor(private loggerService: LoggerService) {}

  public setTranscriptsToStorage(chatTranscripts: { [contactId: string]: ITranscript[] }) {
    const functionName = 'addTranscriptsToStorage';
    try {
      this.transcriptMap = chatTranscripts;
      localStorage.setItem('TranscriptMap', JSON.stringify(this.transcriptMap));
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, 'RingCentralStorageService : ERROR', error);
    }
  }
}
