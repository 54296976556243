import { Injectable } from '@angular/core';
import { IMetadata } from '@amc-technology/ui-library';
import * as api from '@amc-technology/davinci-api';
import { ConnectService } from './connect.service';
import { LoggerService } from './logger.service';
import { IConfiguration } from './model/IConfiguration';
import { ConfigurationService } from './configuration.service';
import { LOG_LEVEL } from '@amc-technology/davinci-api';
import { getContactId } from './util/getContactId';

@Injectable({
  providedIn: 'root'
})
export class ConnectInteractionOperationsService {
  private config: IConfiguration;
  private log: (logLevel: LOG_LEVEL, fName: string, message: string, object?: any) => void = () => {};

  constructor(private configService: ConfigurationService, private _connectService: ConnectService, private loggerService: LoggerService) {
    this.log = this.loggerService.log;
  }

  initialize(): void {
    this.config = this.configService.config; // Initialize config on first line as subsequent methods will be accessing this variable.
  }

  public hangupOperationBuilder(contactid: string, connectionid?: string) {
    const fName = 'hangupOperationBuilder';
    return {
      operationName: 'Hang Up',
      icon: new URL(this.config.iconPack + 'voice_end_normal.png'),
      title: 'Hang Up',
      handler: (operationName: string, operationMetadata?: IMetadata[]) => {
        try {
          this.log(LOG_LEVEL.Debug, `ConnectInteractionOperationsService ${fName} handler`, `operationName: ${operationName}`);
          this.log(LOG_LEVEL.Debug, `ConnectInteractionOperationsService ${fName} handler`, `operationMetaData: `, operationMetadata);
          this.loggerService.logger.logDebug(
            `ConnectInteractionOperationsService hangupOperation triggered
            uuid=${contactid}`
          );
          this._connectService.EndCall(contactid, connectionid);
        } catch (e) {
          this.loggerService.logger.logError(
            `ConnectInteractionOperationsService hangupOperation:
            uuid=${contactid}
            error=${JSON.stringify(e)}`
          );
        }
      }
    };
  }

  public disabledEndCall(contactId: string, connectionId: string) {
    const fName = 'disabledEndCall';
    return {
      operationName: 'Hang Up',
      icon: this.config.iconPack + 'voice_end_normal.png',
      title: 'Hang Up',
      handler: (operationName: string, operationMetadata?: IMetadata[]) => {
        this.log(LOG_LEVEL.Debug, `ConnectInteractionOperationsService ${fName} handler`, `operationName: ${operationName}`);
        this.log(LOG_LEVEL.Debug, `ConnectInteractionOperationsService ${fName} handler`, `operationMetaData: `, operationMetadata);
        this._connectService.EndCall(contactId, connectionId);
      }
    };
  }

  public blindTransferBuilder() {
    const fName = 'blindTransferBuilder';
    return {
      operationName: 'Blind Transfer',
      icon: new URL(this.config.iconPack + 'voice_blindtransfer_normal.png'),
      title: 'Blind Transfer',
      handler: async (operationName: string, operationMetadata?: IMetadata[]) => {
        try {
          this.log(LOG_LEVEL.Debug, `ConnectInteractionOperationsService ${fName} handler`, `operationName: ${operationName}`);
          this.log(LOG_LEVEL.Debug, `ConnectInteractionOperationsService ${fName} handler`, `operationMetaData: `, operationMetadata);
          const contact = await api.contextualOperation(api.CONTEXTUAL_OPERATION_TYPE.BlindTransfer, api.CHANNEL_TYPES.Telephony).catch((error) => {
            this.log(LOG_LEVEL.Error, `ConnectInteractionOperationsService ${fName} handler`, `Error: `, error);
          });
          if (contact) {
            this._connectService.coldTransferCall(getContactId(contact));
          }
        } catch (e) {
          this.log(LOG_LEVEL.Error, `ConnectInteractionOperationsService ${fName} handler`, `Error: `, e);
        }
      }
    };
  }

  public processTransfer(contactId: string, transferType: string) {
    const fName = 'processTransfer';
    return {
      operationName: 'Join and drop',
      icon: new URL(this.config.iconPack + 'voice_check_normal.png'),
      title: transferType,
      handler: (operationName: string, operationMetadata?: IMetadata[]) => {
        try {
          this.log(LOG_LEVEL.Debug, `ConnectInteractionOperationsService ${fName} handler`, `operationName: ${operationName}`);
          this.log(LOG_LEVEL.Debug, `ConnectInteractionOperationsService ${fName} handler`, `operationMetaData: `, operationMetadata);
          if (transferType === 'Warm Transfer') {
            this._connectService.completeWarmTransfer();
          } else {
            this._connectService.conferenceCall(contactId);
          }
        } catch (e) {
          this.log(LOG_LEVEL.Error, `ConnectInteractionOperationsService ${fName} handler`, `Error: `, e);
        }
      }
    };
  }

  public conferenceBuilder() {
    const fName = 'conferenceBuilder';
    return {
      operationName: 'Conference',
      icon: new URL(this.config.iconPack + 'voice_conference_normal.png'),
      title: 'Conference',
      handler: async (operationName: string, operationMetadata?: IMetadata[]) => {
        try {
          this.log(LOG_LEVEL.Debug, `ConnectInteractionOperationsService ${fName} handler`, `operationName: ${operationName}`);
          this.log(LOG_LEVEL.Debug, `ConnectInteractionOperationsService ${fName} handler`, `operationMetaData: `, operationMetadata);
          const contact: api.IContextualContact = await api.contextualOperation(api.CONTEXTUAL_OPERATION_TYPE.Conference, api.CHANNEL_TYPES.Telephony).catch((error) => {
            this.log(LOG_LEVEL.Error, `ConnectInteractionOperationsService ${fName} handler`, `Error: `, error);
            return null;
          });
          if (contact.displayName) {
            this._connectService.warmTransferCall(getContactId(contact), 'conference');
          }
        } catch (e) {
          this.log(LOG_LEVEL.Error, `ConnectInteractionOperationsService ${fName} handler`, `Error: `, e);
        }
      }
    };
  }

  public warmTransferBuilder() {
    const fName = 'warmTransferBuilder';
    return {
      operationName: 'Warm Transfer',
      icon: new URL(this.config.iconPack + 'voice_warmtransfer_normal.png'),
      title: 'Warm Transfer',
      handler: async (operationName: string, operationMetadata?: IMetadata[]) => {
        try {
          this.log(LOG_LEVEL.Debug, `ConnectInteractionOperationsService ${fName} handler`, `operationName: ${operationName}`);
          this.log(LOG_LEVEL.Debug, `ConnectInteractionOperationsService ${fName} handler`, `operationMetaData: `, operationMetadata);
          const contact: api.IContextualContact = await api.contextualOperation(api.CONTEXTUAL_OPERATION_TYPE.WarmTransfer, api.CHANNEL_TYPES.Telephony).catch((error) => {
            this.log(LOG_LEVEL.Error, `ConnectInteractionOperationsService ${fName} handler contextual operation`, `Error: `, error);
            return null;
          });
          if (contact.displayName) {
            this._connectService.warmTransferCall(getContactId(contact));
          }
        } catch (e) {
          this.log(LOG_LEVEL.Error, `ConnectInteractionOperationsService ${fName} handler`, `Error: `, e);
        }
      }
    };
  }

  public answerOperationBuilder(uuid: string) {
    const fName = 'answerOperationBuilder';
    return {
      operationName: 'Answer',
      icon: new URL(this.config.iconPack + 'voice_alerting_answer_normal.gif'),
      title: 'Answer',
      handler: (operationName: string, operationMetadata?: IMetadata[]) => {
        try {
          this.log(LOG_LEVEL.Debug, `ConnectInteractionOperationsService ${fName} handler`, `operationName: ${operationName}`);
          this.log(LOG_LEVEL.Debug, `ConnectInteractionOperationsService ${fName} handler`, `operationMetaData: `, operationMetadata);
          this.loggerService.logger.logDebug(
            `ConnectInteractionOperationsService answerOperation triggered
            uuid=${uuid}`
          );
          this._connectService.AnswerCall(uuid);
        } catch (e) {
          this.loggerService.logger.logError(
            `ConnectInteractionOperationsService ${fName}:
            uuid=${uuid}
            error=${JSON.stringify(e)}`
          );
        }
      }
    };
  }

  public holdOperationBuilder(uuid: string) {
    const fName = 'holdOperationBuilder';
    return {
      operationName: 'Hold',
      icon: new URL(this.config.iconPack + 'voice_hold_normal.png'),
      title: 'Hold',
      handler: (operationName: string, operationMetadata?: IMetadata[]) => {
        try {
          this.log(LOG_LEVEL.Debug, `ConnectInteractionOperationsService ${fName} handler`, `operationName: ${operationName}`);
          this.log(LOG_LEVEL.Debug, `ConnectInteractionOperationsService ${fName} handler`, `operationMetaData: `, operationMetadata);
          this.loggerService.logger.logDebug(
            `ConnectInteractionOperationsService holdOperation triggered
            uuid=${uuid}`
          );
          this._connectService.HoldCall(uuid);
        } catch (e) {
          this.loggerService.logger.logError(
            `ConnectInteractionOperationsService holdOperation:
            uuid=${uuid}
            error=${JSON.stringify(e)}`
          );
        }
      }
    };
  }

  public resumeOperationBuilder(uuid: string) {
    const fName = 'resumeOperationBuilder';
    return {
      operationName: 'Resume',
      icon: new URL(this.config.iconPack + 'voice_unhold_normal.png'),
      title: 'Resume',
      handler: (operationName: string, operationMetadata?: IMetadata[]) => {
        try {
          this.log(LOG_LEVEL.Debug, `ConnectInteractionOperationsService ${fName} handler`, `operationName: ${operationName}`);
          this.log(LOG_LEVEL.Debug, `ConnectInteractionOperationsService ${fName} handler`, `operationMetaData: `, operationMetadata);
          this.loggerService.logger.logDebug(
            `ConnectInteractionOperationsService resumeOperation triggered
            uuid=${uuid}`
          );
          this._connectService.ResumeCall(uuid);
        } catch (e) {
          this.loggerService.logger.logError(
            `ConnectInteractionOperationsService resumeOperation:
            uuid=${uuid}
            error=${JSON.stringify(e)}`
          );
        }
      }
    };
  }
}
